.react-autosuggest__input {
    position: relative;
    max-height: 30px !important;
    min-width: 200px !important;
    line-height: 1.2 !important;
}

.react-autosuggest__input {
    height: 12px;
    padding: 2px;
}

.react-autosuggest__input {
    width: 60px;
    height: 30px;
    color: #3174ad;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input::-ms-clear {
    display: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
    z-index: 2;
}

.react-autosuggest__suggestions-container--open {
    display: inline-block;
    position: absolute;
    width: 200px;
    border: 1px solid #3174ad;
    color: white;
    background-color: #1e436a;
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 3000;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    height: 300px;
    overflow-y: auto;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 2px;

}

.react-autosuggest__suggestion--highlighted {
    background-color: #3174ad;
    color: white;
}

.react-datepicker__input-container input {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: 22px;
    border: none;
    color: #224668;

}

.filepond--root {
    margin-bottom: 0;
}

