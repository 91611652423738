
.MuiDrawer-paper {
    width: 75%;
    height: 100% !important;
    align-items: flex-start;
    padding: 0 !important;
}

.preferences-carousel-container {
    padding: 20px;
    /*width: 90% !important;*/
    left: 0;
}

.preferences-carousel-container h1 {
    margin: 0;
    color: #224668;
    font-weight: 300;
}

.preferences-carousel-container h1,
.preferences-carousel-container h2,
.preferences-carousel-container h3,
.preferences-carousel-container h4,
.preferences-carousel-container h5,
.preferences-carousel-container h6 {
    margin: 5px;
    font-weight: 300;
}

.save-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
}

.save-button, .commit-button {
    background-color: #224668 !important;
    color: white !important;
    padding: 10px !important;
    height: 30px !important;
    align-self: end;
}

.save-button:hover, .commit-button:hover {
    background-color: rgba(1, 58, 101, 0.5) !important;
    color: darkslategrey !important;
    height: 30px !important;
    align-self: end;
}

.preferences-selector {
    width: 100%;
    max-height: 90vh;
}

.preferences-drawer {

}
