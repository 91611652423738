
.slot.fc-time-grid-event {
    background-color: rgba(69, 117, 180, 0.29) !important;
    color: darkslategrey;
    overflow: hidden;
    text-overflow: ellipsis;
}

.slot.edited.fc-time-grid-event {
    background-color: rgba(255, 195, 32, 0.25) !important;
    overflow-x: hidden;
    text-overflow: ellipsis;

}

.event-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    min-height: 20px !important;
    align-self: baseline;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: none;
    max-width: inherit;
    width: inherit;
    white-space: nowrap;
}


.multi-editor .slot.fc-time-grid-event * {
    text-wrap: none !important;
    text-overflow: ellipsis;
}

.break-event {
    background-color: rgba(64, 72, 86, 0.16) !important;
    color: #224668 !important;
    font-size: 6px;
}

.break-event-active {
    background-color: #d2ded4 !important;
    color: #224668 !important;
    font-size: 6px;

}

.break-event .fc-title {
    font-size: .8em;
}

.break-event .fc-content {
    display: flex;
    flex-direction: row;
}

.desired-event.fc-time-grid-event {
    min-width: fit-content;
    min-height: 20px;
    position: absolute !important;
    background-color: transparent !important;
    color: #4575b4 !important;
    align-items: baseline;
    right: 0 !important;
}

.marker-event.fc-time-grid-event {
    background-color: transparent !important;
    color: #3174ad !important;
    min-width: fit-content !important;
    max-height: 10px;
    pointer-events: none;
}


