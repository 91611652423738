.heat-map {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.heat-map-booking-1 {
    background-color: #d73027 !important;
    color: white !important;
    font-size: 1em !important;

}

.heat-map-booking-2 {
    background-color: #f46d43 !important;
    color: white !important;

}

.heat-map-event-content {
    height: 20px;
    pointer-events: none;
}



.heat-map-booking-3 {
    background-color: #fee090 !important;
    color: black !important;

}

.heat-map-booking-4 {
    background-color: #e0f3f8 !important;
    color: grey !important;

}

.heat-map-booking-5 {
    background-color: #abd9e9 !important;
    color: grey !important;
}

.heat-map-booking-6 {
    background-color: #74add1 !important;
    color: white !important;
}

.heat-map-booking-7 {
    background-color: #4575b4 !important;
    color: white !important;

}

.heat-map-booking-8 {
    background-color: #404c86 !important;
    color: white !important;
}

.heat-map-tta-wait-1 {
    background-color: #4575b4 !important;
    color: white !important;

}

.heat-map-tta-wait-2 {
    background-color: #74add1 !important;
    color: white !important;
}

.heat-map-tta-wait-3 {
    background-color: #abd9e9 !important;
    color: grey !important;
}

.heat-map-tta-wait-4 {
    background-color: #e0f3f8 !important;
    color: grey !important;
}

.heat-map-tta-wait-5 {
    background-color: #fee090 !important;
    color: black !important;
}

.heat-map-tta-wait-6 {
    background-color: #fdae61 !important;
    color: black !important;

}

.heat-map-tta-wait-7 {
    background-color: #f46d43 !important;
    color: white !important;
}

.heat-map-tta-wait-8 {
    background-color: #d73027 !important;
    color: white !important;

}

.fc-time-grid .fc-slats td {
    height: 2em;
    border-bottom: 0;
    /* each cell is responsible for its top border */
}

.heat-map-booking-button, .heat-map-wait-button, .heat-map-tta-wait-button {
    background-color: #41b6c4 !important;
    color: white !important;
    padding: 0 !important;
    height: 30px !important;
    align-self: end;
    border: 3px white;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    opacity: 1;
}

.heat-map-booking-button:focus, .heat-map-wait-button:focus, .heat-map-tta-wait-button:focus {
    border: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-image-repeat: initial;
    opacity: .5;
}

.heat-map-buttons-parent {
    padding: 20px;
    border: #224668;
}

.heat-map-tab {
    background-color: #e0f3f8;
    color: grey;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-weight: 200;
    margin: 10px;
    padding: 20px;
    text-decoration: none;
    border: 1px;
    display: block;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.heat-map-description {
    color: grey;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em;
    font-weight: 200;
}

.heat-map-tab:focus {
    background-color: #fee090;
    outline: none;
}


.fc table {
    font-size: .9em !important;
    font-weight: bold !important;
}

.heat-map .fc-view-container {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.fc-view-container::-webkit-scrollbar {
    display: none;
}

.heat-map .fc-view-container * {
    font-size: 12px;
    font-weight: 300;
}

