.multi-editor {
    display: flex;
    flex-direction: row;
    width: 100%;

}

.multi-editor-left::-webkit-scrollbar {
    display: none;
}

.multi-editor-right::-webkit-scrollbar {
    display: none;
}


.multi-editor-left {
    flex: 1;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;

}

.multi-editor-right {
    flex: 1;
    height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    width: 100%;
    align-items: flex-start;
}

.add-slot-button {
    background: none;
    border: none;
    color: #224668;
}


.add-slot-button:hover {
    color: rgba(1, 52, 101, 0.57);
    cursor: pointer;
}

input[type="checkbox"], input[type="checkbox"] * {
    background-color: lightslategray !important;
    color: lightsteelblue !important;

}

.multi-editor-right table {
    width: 100%;
    border-spacing: 1em;
    text-align: left;
}

