.immutable-event-content {
    /*height: 20px;*/
    pointer-events: none;
    color: white !important;
}


.immutable-event {
    /*max-width: 50%;*/
    border-radius: 3px !important;
    background-color: #4575b4 !important;
}

.immutable-schedule .fc-time-grid .fc-slats td {
    height: 3em !important;
}


.toggle-button-holder {
    margin-top: 40px;
    margin-bottom: 10px;
    border-top: 400px;
}

.toggle-button {
    height: 20px;
    color: white;
    background-color: #4575b4;
    border-radius: 3px;
    margin-top: 40px;
    margin-bottom: 10px;
    border-top: 400px;

}

.immutable-preferences {
    flex: 1;
    /*height: 70vh;*/
    overflow-y: hidden;
    overflow-x: hidden;
    -ms-overflow-style: none;

}

/*.fc-view-container::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*.immutable-schedule .fc-view-container {*/
/*    height: 50vh;*/
/*    overflow-y: scroll;*/
/*    overflow-x: hidden;*/
/*}*/

/*.immutable-schedule  .fc-view-container * {*/
/*    font-size: 12px;*/
/*    font-weight: 300;*/
/*}*/
