
.dynamic-input-edit {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    font-size: x-small;
    color: darkslategrey;
}

.dynamic-input-edit > * {
    padding-right: 10px !important;
    width: 25%

}

.dynamic-input-edit button, .dynamic-input-edit button {
    border: none !important;
}

.dynamic-input {
    min-width: 100%;
    padding-right: 10px;
}


.multi-editor-checkbox {
    align-items: flex-start;
    width: fit-content;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    top: 0;
    border: none !important;
    position: absolute;
    right: 0;
    padding: 0 !important;
}

.modal-buttons > button {
    background-color: transparent;
    border: none;
    color: #224668;
}

.modal-buttons > button:active {
    background-color: transparent;
    border: none;
    color: #224668;
}

.dynamic-input-label {
    font-weight: bold;
    color: #224668;
    padding-right: 10px;
}

.slot-display {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.slot-display label {
    font-size: 24px;
    color: #224668;


}

.slot-display > div {
    padding: 10px;
}


.metadata-display {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}

.metadata-row {
    padding-left: 20px;

}

.metadata-editor-row {
    display: flex;
    flex-direction: row;

}

.metadata-editor-row input, .metadata-editor-row option {
    font-size: x-small !important;
}


.metadata-display sup {
    font-size: 10px !important;
    color: #224668;
    margin-left: 20px;

}

