
.fc-unthemed td.fc-today {
    background: none !important;
}

.fc-event {
    border: none !important;
    border-radius: 0 !important;
    padding-top: 2px;
    padding-left: 2px;
}

.fc-time-grid-container {
    /* */
    overflow: hidden !important;
    height: auto !important;
}

.fc table {
    font-size: .9em !important;
    font-weight: bold !important;
}

.fc-time > span {
    font-size: .8em !important;
}

.fc-day-grid {
    visibility: hidden;
    height: 0;
}

.fc-time-grid .fc-slats td {
    height: 1em !important;
}


.fc-axis > span {
    font-size: 12px;
}


