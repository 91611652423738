.slot-editor-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
    padding-bottom: 20px;

}

.slot-editor-row > div {
    display: flex;
    width: 50%;
    padding-right: 10px;
}

.slot-editor-row input {
    width: 50%;
}

.slot-editor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;

}

.slot-editor-modal {
    position: absolute;
    z-index: 8000;
    padding: 50px;
    background-color: white;
    box-shadow: 0 0 20px darkslategrey;
    border-color: lightsteelblue;
    border-style: inset;
    border-width: 1px;
    min-width: 350px;
    max-height: 90%;
    overflow-y: auto;

}

.slot-editor-modal::-webkit-scrollbar {
    display: none;
}


.slot-editor-modal input {
    width: 90%;
    border-color: lightsteelblue;
    border-width: 1px;

}

.slot-editor-modal select {
    background-color: #224668;
    color: whitesmoke;
    border: none;

}


.slot-editor-modal label, .slot-editor-modal span {
    font-size: .8em !important;
}


.slot-editor-modal td {
    display: inline-block;
}


