
body * {
    font-family: Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
}

.ah_row {
    position: relative;
    width: 100%;
    display: table-row
}

.ah_row:after {
    content: '';
    display: table-row;
    clear: both;

}

.MuiButton-contained {
    margin: 0 5px 0 5px !important;
}

.ah_column {
    float: left;
    width: 50%;
}

.fullSize {
    width: 100%;
    height: 100%;
}

.ah_nav {
    background: linear-gradient(45deg, #C5DAFF, #000000);
    top: 0;
    left: -10px;
    width: 100%;
    display: inline-block;
    color: #ffffff;
    z-index: 3000
}

.ah_login_card {
    margin-top: 10px;
    width: 400px;
    top: 30%;
    overflow: scroll;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -30%);
    text-align: center
}

.ah-carousel {
    display: flex;
    width: 100% !important;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.ah-carousel-content {
    width: 100%
}

div.main-svg {
    width: 100%;
    height: 100%
}

.MuiGrid-root .js-plotly-plot {
    height: 450px;
}

.content {
    position: absolute;
    top: 10px;
    left: -10px;
    width: 100%;
    content: '';
    display: table;
    clear: both;
    z-index: 1000;
}

.label {
    padding: 1em 1em 1em 1em
}

.ah_sub_nav {
    top: 0 !important;
    background: #0088B4;
    color: #FFFFFF !important;
    width: 100%;
    height: 20px;
    display: table;
    clear: both;
}

.bottom-menu-container > .ah_sub_nav {
    background: #224668;
}

.bottom-menu-container {
    position: absolute;
    background: #224668;
    top: calc(3em + 40px);
    height: 20px;
    width: 100%;
    z-index: 1000;
}

.sub-menu {
    position: absolute;
    top: 3em;
    height: 3em;
    width: 100%;
}

.sub-menu > .menu-container-spacer {
    background: #0088B4;
}

.menu-container-spacer {
    background: #224668;
    width: 2em;
    height: 40px;
}

.top-menu-tabs {
    cursor: default !important;
    color: #FFFFFF !important;
}

.MuiTabs-indicator{
    padding: 0 !important
}

.assist-buttons{
    height: 3em !important;
    float: right !important;
    display: table-column !important;
    vertical-align: middle !important;
    color: #FFFFFF !important;
    font-size: 10px !important;
}
