
.fc-widget-content {
    background-color: transparent;
    font-family: sans-serif;
    font-weight: 100;
    font-size: .75em;
}

.fc-widget-header {
    background-color: transparent;
}

.fc-time-grid-event {
    color: #404856;
    font-size: 1em !important;
    font-weight: 400 !important;
    min-height: 10px !important;
    border: none !important;
}


.fc-axis {
    background-color: transparent;
}

.fc-day-grid {
    height: 0;
}
