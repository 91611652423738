
.counter-table {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 3em 0;
    padding: 50px;
    padding-top: 0px !important;
    width: 100%;
}

.counter-table-cell {
    box-sizing: border-box;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
    padding: 0.8em 1.2em;
    overflow: hidden;
    list-style: none;
    border-bottom: solid 1px rgba(1, 58, 101, 0.52);
}

.counter-table-cell > h1,
.counter-table-cell > h2,
.counter-table-cell > h3,
.counter-table-cell > h4,
.counter-table-cell > h5,
.counter-table-cell > h6 {
    margin: 0;
}

/* Table column sizing
================================== */
.counter-table--2cols > .counter-table-cell {
    width: 50%;
    font-weight: 300;
    font-size: .5em;
}

.counter-table--1cols > .counter-table-cell {
    width: 100%;
    font-weight: 300;
    font-size: .5em;
}

.counter-table-cell > h3, .counter-table div {
    font-weight: 100;
    margin: 0;
    font-size: 1.4em;
}
