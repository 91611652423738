.sliders-holder {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    flex-direction: row;
}

.slider-container {
    width: 50%;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
}

.schedule_row:hover{
    cursor: pointer;
    background-color: #f4f4f4;
}
