
.fade-enter {
    opacity: 0;
    z-index: -1;
}

.fade-enter-active {
    opacity: 1;
    z-index: 10;
    transition: z-index 500ms, opacity 500ms ease-in;
}

.fade-exit {
    opacity: 1;
    z-index: 10;
}

.fade-exit-done {
    opacity: 0;
    z-index: 10;
    left: 8000px
}

.fade-exit-active {
    opacity: 0;
    z-index: -1;
    transition: z-index 500ms, opacity 500ms ease-in;
}

.fade {
    position: absolute;
    width: 100%
}


